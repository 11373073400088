import React from 'react';
import TabbedImageGallery from './ImageSlider_V3';

const App = () => {
  const imageGroups = [
    {
      tabLabel: 'Breakfast',
      images: [
        "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(2)_gHaGX2epe.png?updatedAt=1723871940452",
        "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(3)_IfVx2RAES.png?updatedAt=1723871932901",

      ],
      altText: ['First Image', 'Second Image'],
    },
    {
      tabLabel: 'Brunch',
      images: [
        "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/updates/menu_20240904_091658_0001_pYI-lzt9q.png?updatedAt=1725437963904",
        "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(11)_r1CaeezuZ.png?updatedAt=1723871988903",
        "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(12)_0cJT90EGK.png?updatedAt=1723871989071",
      ],
      altText: ['brunch 1', 'brunch 2', 'brunch 3'],
    },

    {
        tabLabel: 'Special',
        images: [
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(1)__7mYa7Oh4.png?updatedAt=1723871940820",
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/updates/menu_20240904_091658_0000_-IZjqvt5rO.png?updatedAt=1725437963805",
        ],
        altText: ['special 1', 'special 2'],
      },


      {
        tabLabel: 'Salads',
        images: [
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(8)_mhvegU6pF.png?updatedAt=1723871940209",
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(9)_6mI2TdBCB.png?updatedAt=1723871939638",
        ],
        altText: ['Salads 1', 'Salad 2'],
      },

      {
        tabLabel: 'Nigerian Soups & Swallow',
        images: [
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(4)_LJr-BGPCX.png?updatedAt=1723871944784",
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(5)_08u10h4fl.png?updatedAt=1723871941724",
        ],
        altText: ['soup1', 'Soup 2'],
      },
      {
        tabLabel: 'Sandwiches',
        images: [
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(7)_kO3-YyhxA.png?updatedAt=1723871940193",
        ],
        altText: ['sandwish 1'],
      },
      {
        tabLabel: 'Drinks',
        images: [
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(13)_rFt5_Up0z.png?updatedAt=1723871973894",
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(14)_i85qngzjo.png?updatedAt=1723871986145",
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(15)_CfsPA0HeQ.png?updatedAt=1723871986715",
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(16)_tCcxW2fO8.png?updatedAt=1723871985477",
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(17)_Fbn52v3w5.png?updatedAt=1723871982843",
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(18)_4p9uGw24B.png?updatedAt=1723871987051",
            "https://ik.imagekit.io/m0adxj6it/Eli&LuLu/menu/Menu%20(19)__wLFMLxc0.png?updatedAt=1723871987178"

        ],
        altText: ['Drinks 1', 'Drinks 2', 'Drinks 3', 'Drinks 4', 'Drinks 5', 'Drinks 6', 'Drinks 7'],
      },
      


    // Add more groups as needed
  ];

  return (
    <div className="App">
      <h1 className='flex justify-center items-center mt-5 bg-white z-10'>Our Menu List</h1>
      <TabbedImageGallery imageGroups={imageGroups} />
    </div>
  );
};

export default App;