import React, { useState } from 'react';
import { Tab, Tabs, Box } from '@mui/material';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const TabbedImageGallery = ({ imageGroups }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Image Gallery Tabs"
      >
        {imageGroups.map((group, index) => (
          <Tab label={group.tabLabel} key={index} />
        ))}
      </Tabs>
      <Box sx={styles.imageContainer}>
        {imageGroups[selectedTab].images.map((image, index) => (
          <Zoom key={index}>
            <img
              src={image}
              alt={imageGroups[selectedTab].altText[index] || `Image ${index + 1}`}
              style={styles.image}
            />
          </Zoom>
        ))}
      </Box>
    </Box>
  );
};

const styles = {
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
    marginTop: '20px',
  },
  image: {
    maxWidth: '100%',
    marginBottom: '10px',
    cursor: 'zoom-in',
  },
};

export default TabbedImageGallery;